import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { AdminSideNavComponent } from "./admin-side-nav.component";

@NgModule({
  declarations: [AdminSideNavComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatDividerModule],
  exports: [AdminSideNavComponent],
})
export class AdminSideNavModule {}
