import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import {
  DESJARDINS_EMAIL_DOMAINS,
  LA_RUCHE_EMAIL_DOMAINS,
  ThirdPartyAuthPlatform,
} from "@app/services-v2/third-party-auth/third-party-auth-platform";
import { PopupComponent } from "@app/shared/components/layout/popup/popup.component";
import { I18nString } from "lrd-interfaces/interfaces";
import { firstValueFrom, Subject } from "rxjs";

interface IAppSettings {
  appClientId: string;
  baseAuthUrl: string;
  stripeClientId: string;
  stripePublicApiKey: string;
  facebookPageId: string;
  googleAnalyticsCode: string;
  googleAnalyticsSecondaryCode: string;
  recaptchaSiteKey: string;
  websocketEndpoint: string;
}

@Injectable({
  providedIn: "root",
})
export class AppSettings {
  private _isLoaded = false;
  private _appSettings: IAppSettings;

  get isLoaded(): boolean {
    return this._isLoaded;
  }

  public appSettings$ = new Subject<AppSettings>();

  get stripeApiKey(): string {
    return this._appSettings.stripePublicApiKey;
  }

  get facebookPageId(): string {
    return this._appSettings.facebookPageId;
  }

  get googleAnalyticsCode(): string {
    return this._appSettings.googleAnalyticsCode;
  }

  get googleAnalyticsSecondaryCode(): string {
    return this._appSettings.googleAnalyticsSecondaryCode;
  }

  get recaptchaSiteKey(): string {
    return this._appSettings?.recaptchaSiteKey;
  }

  get websocketEndpoint(): string {
    return this._appSettings.websocketEndpoint;
  }

  // Customer request : disable profile delete option (user and organization). Change this to true to re-enable it later.
  get isProfileDeleteOptionEnabled(): boolean {
    return false;
  }

  get zohoNewletterSubscribeLink(): I18nString {
    return {
      fr: "https://zc.vg/sZ5t8",
      en: "https://zc.vg/Kyyil",
    };
  }

  get facebookPixelId(): string {
    return "312822422849218";
  }

  constructor(public dialog: MatDialog) {}

  public async getSsoProvider(domain: string): Promise<ThirdPartyAuthPlatform | null> {
    if (DESJARDINS_EMAIL_DOMAINS.includes(domain)) {
      await this.popupSsoDesjardinsMessage();
      return ThirdPartyAuthPlatform.DESJARDINS;
    }

    if (LA_RUCHE_EMAIL_DOMAINS.includes(domain)) {
      return ThirdPartyAuthPlatform.LA_RUCHE_QUEBEC;
    }

    return null;
  }

  async init(response: IAppSettings) {
    sessionStorage.setItem("appSettings", JSON.stringify(response));
    this._appSettings = response;
    this._isLoaded = true;
    this.appSettings$.next(this);
  }

  private popupSsoDesjardinsMessage(): Promise<void> {
    const dialog = this.dialog.open(PopupComponent, {
      disableClose: false,
      width: "650px",
      autoFocus: false,
      data: {
        title: "LOGIN_SSO_DESJARDINS_POPUP_TITLE",
        text: "LOGIN_SSO_DESJARDINS_POPUP_TXT",
        closeButtonHidden: true,
        actions: [{ label: "OK", color: "yellow" }],
      },
    });

    return firstValueFrom(dialog.afterClosed());
  }
}
