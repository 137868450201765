import { StateData } from "@shared-v2/utils/state-data";

export function isInstanceOf<T>(clazz: Class<T>): FilterFunction<T> {
  return (value) => value instanceof clazz;
}

export function isInstanceOfEither<T, CLASSES extends Class<T>[]>(
  ...classes: CLASSES
): FilterFunction<InstanceType<CLASSES[number]>> {
  return passesEither(...classes.map((clazz) => isInstanceOf(clazz)));
}

export function byPropValue<T, K extends keyof T>(
  propName: K,
  expectedValue: T[K],
): FilterFunction<T> {
  return (value: T) => value && value[propName] === expectedValue;
}

export function isNotPending<T>(): FilterFunction<StateData<T>> {
  return (stateData) => !stateData.isPending();
}

export function isNotEmpty<T>(): FilterFunction<T> {
  return (value) => value != null;
}

export function passesEither<T, FILTERS extends FilterFunction<T>[]>(
  ...filters: FILTERS
): FilterFunction<Parameters<FILTERS[number]>[0]> {
  return (value) => {
    return filters.some((filterFn) => filterFn(value));
  };
}

export function passesAll<T>(...filters: FilterFunction<T>[]): FilterFunction<T> {
  return (value) => {
    return filters.every((filterFn) => filterFn(value));
  };
}
