import {
  IPromotionBanner,
  IPromotionBannerAsset,
  PromotionBannerSizeEnum,
} from "lrd-interfaces/interfaces";
import { PromotionBannerViewModel, imageSet } from "./promotion-banner-view-model";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PromotionBannerFactory {
  public createPromotionBannerViewModel(
    promotionBanner: IPromotionBanner,
  ): PromotionBannerViewModel {
    const imageSets = promotionBanner.assets
      .map(convertAssetToImageSet)
      .sort((a, b) => b.width - a.width);
    const defaultImage = getSmallestImageSet(imageSets);

    return {
      id: promotionBanner.promotionBannerId,
      imageSets,
      defaultImage,
      link: promotionBanner.link,
    };
  }
}

const getSmallestImageSet = (imageSets: imageSet[]) => {
  return imageSets.reduce((smallestImageSet, imageSet) => {
    if (imageSet.width < smallestImageSet.width) {
      return imageSet;
    }

    return smallestImageSet;
  });
};

const convertAssetToImageSet = (asset: IPromotionBannerAsset) => {
  const width = getWidthBySize(asset.size);

  return {
    width: width,
    height: 100,
    url: asset.url,
  };
};

const getWidthBySize = (size: PromotionBannerSizeEnum) => {
  switch (size) {
    case PromotionBannerSizeEnum.W343:
      return 343;
    case PromotionBannerSizeEnum.W768:
      return 768;
    case PromotionBannerSizeEnum.W1200:
      return 1200;
  }
};
