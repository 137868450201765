<div class="label-wrapper" *ngIf="label || tip">
  <label class="label" [for]="fieldId" (click)="onLabelClick($event)">
    <span *ngIf="label" class="label-text">
      {{ label }}

      <app-v2-icon
        *ngIf="toolTip"
        class="tooltip-icon"
        icon="help"
        size="small"
        [matTooltip]="toolTip"
        matTooltipPosition="above"
      />
    </span>

    <span *ngIf="tip" class="tip-text">{{ tip }}</span>
  </label>

  <div *ngIf="showHints || hint" class="hints">
    <ng-content select="app-lrd-text-counter" />

    <span *ngIf="hint; else requiredHint" class="hint">{{ hint }}</span>

    <ng-template #requiredHint>
      <span *ngIf="isRequired()" class="hint">
        {{ "components.lrd-form-field.labels.required" | translatable }}
      </span>
    </ng-template>
  </div>
</div>

<div>
  <div
    class="field-wrapper"
    [ngClass]="{
      'field-wrapper--has-icon': allowIcon() && (icon || hasErrors()),
      'field-wrapper--has-action-field': hasFieldAction()
    }"
  >
    <ng-content select="input,textarea,app-lrd-select,app-lrd-stripe-element" />

    <ng-container *ngIf="allowIcon() && (icon || hasErrors())">
      <app-v2-icon
        class="field-icon"
        *ngIf="hasErrors() && handleErrors; else iconTemplate"
        icon="info"
        size="medium"
      />

      <ng-template #iconTemplate>
        <app-v2-icon *ngIf="icon" class="field-icon" [icon]="icon" size="medium" />
      </ng-template>
    </ng-container>
  </div>

  <ng-content select="app-lrd-form-field-action" />
</div>

<div
  class="errors"
  *ngIf="(handleErrors && hasErrors()) || contentChildrenErrors.length"
  @showErrors
>
  <app-lrd-error *ngFor="let error of errors()">{{ error }}</app-lrd-error>
  <ng-content select="app-lrd-error" />
</div>
