import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonArrayComponent } from "./button-array.component";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { TranslationsModule } from "../../translations/translations.module";

@NgModule({
  declarations: [ButtonArrayComponent],
  imports: [CommonModule, MatCardModule, TranslationsModule],
  exports: [ButtonArrayComponent],
})
export class ButtonArrayModule {}
