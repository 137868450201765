import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LrdInputDirective } from "@shared-v2/components/lrd-form-field/lrd-input.directive";
import { ColorPickerModule } from "ngx-color-picker";
import { AngularCropperjsModule } from "angular-cropperjs";
import { NgxMaskDirective } from "ngx-mask";
import { QuillModule } from "ngx-quill";
import { MatBadgeModule } from "@angular/material/badge";

import { SafePipe } from "./pipes/dom-sanitizer-pipe";
import { FileSizePipe } from "src/app/shared/pipes/size-pipe";
import { ReplacePipe } from "src/app/shared/pipes/replace-pipe";
import { Highlight } from "./pipes/highlight-all-pipe";
import { TranslatePipe } from "./translations/translate.pipe";

import { CroppieLegacyComponent } from "./components/croppie-legacy/croppie-legacy.component";
import { TranslationsModule } from "src/app/shared/translations/translations.module";

import { ButtonArrayModule } from "./components/button-array/button-array.module";
import { AdminSideNavModule } from "./components/admin-side-nav/admin-side-nav.module";
import { HighlightFirstWord } from "./pipes/highlight-pipe";
import { SnackbarModule } from "./components/snackbar/snackbar.module";
import { InlineEditorModule } from "./components/inline-editor/inline-editor.module";
import { OptionAutocompleteSearchComponent } from "./components/option-autocomplete-search/option-autocomplete-search.component";
import { EmptyTableComponent } from "src/app/shared/components/empty-table/empty-table.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons/faExchangeAlt";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons/faCaretUp";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons/faNewspaper";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTwitterSquare } from "@fortawesome/free-brands-svg-icons/faTwitterSquare";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { faYoutubeSquare } from "@fortawesome/free-brands-svg-icons/faYoutubeSquare";
import { faGoogle } from "@fortawesome/free-brands-svg-icons/faGoogle";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons/faEnvelope";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons/faCreditCard";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons/faFileAlt";

import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatLegacySliderModule as MatSliderModule } from "@angular/material/legacy-slider";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { DatePickerComponent } from "./components/date-picker/date-picker.component";
import { ImageUploaderComponent } from "./components/image-uploader/image-uploader.component";
import { HandlingErrorMessagesPipe } from "./pipes/handling-error-messages.pipe";
import { DollarSignPipe } from "./pipes/dollar-sign.pipe";
import { FallbackLanguage } from "./pipes/fallback-pipe";
import { CurrencyPipeRuchePipe } from "./pipes/currency-pipe-ruche.pipe";
import { DatePipeRuchePipe } from "./pipes/date-pipe-ruche.pipe";
import { VideoUrlPipe } from "./pipes/video-url.pipe";
import { PhonePipeRuchePipe } from "./pipes/phone-pipe-ruche.pipe";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { PageContentComponent } from "./components/layout/page-content/page-content.component";
import { PageContentWrapperComponent } from "./components/layout/page-content-wrapper/page-content-wrapper.component";
import { PageContentActionBarComponent } from "./components/layout/page-content-action-bar/page-content-action-bar.component";
import { FilterBarComponent } from "./components/layout/filter-bar/filter-bar.component";
import { CheckboxComponent } from "./components/form/checkbox/checkbox.component";
import { SliderComponent } from "./components/form/slider/slider.component";
import { RouterModule } from "@angular/router";
import { ButtonComponent } from "./components/form/button/button.component";
import { ButtonComponent as ButtonV2Component } from "@app/shared-v2/components/button/button.component";
import { ProjectSummaryPopupComponent } from "@admin/projects/project-summary/project-summary-popup/project-summary-popup.component";
import { TextAreaComponent } from "./components/form/text-area/text-area.component";
import { ContextualNavBarComponent } from "@app/shared-v2/components/contextual-nav-bar/contextual-nav-bar.component";
import { LongLocalizePipe } from "./pipes/long-localize.pipe";
import { SocialShareComponent } from "./components/social-share/social-share.component";
import { ConsoleBreadCrumbsComponent } from "./components/console-bread-crumbs/console-bread-crumbs.component";
import { NumberInputComponent } from "./components/form/number-input/number-input.component";
import { MobileDisabledComponent } from "./components/mobile-disabled/mobile-disabled.component";
import { MatSortModule } from "@angular/material/sort";
import { MatRippleModule } from "@angular/material/core";
import { ContributionTypeConverterPipe } from "./pipes/contribution-type-converter.pipe";
import { ProgramRoleConverterPipe } from "./pipes/program-role-converter.pipe";
import { ProjectFinAddStatusConverterPipe } from "./pipes/project-finadd-status-converter";
import { PaymentStatusConverterPipe } from "./pipes/payment-status-converter.pipe";
import { LowProfileButtonComponent } from "./components/form/low-profile-button/low-profile-button.component";
import { PopupComponent } from "./components/layout/popup/popup.component";
import { YesNoSelectorComponent } from "@shared/components/form/option-selector-form/yes-no-selector.component";
import { SelectComponent } from "@shared/components/form/simple-select-form/select.component";
import { MobileActionBarComponent } from "./components/layout/mobile-action-bar/mobile-action-bar.component";
import { ContributionStatusConverterPipe } from "./pipes/contribution-status-converter.pipe";
import { SplitLayoutWithStickyNavigationComponent } from "./components/layout/split-layout-with-sticky-navigation/split-layout-with-sticky-navigation.component";
import { BannerComponent } from "./components/banner/banner.component";
import { ErrorsComponent } from "./components/form/errors/errors.component";
import { PrintablePageHeaderComponent } from "./components/layout/printable-page-header/printable-page-header.component";
import { IconTitleContainerComponent } from "@app/shared-v2/components/icon-tile-container/icon-title-container.component";
import { LoupeComponent } from "@shared/components/loupe/loupe.component";
import { OpenLinkComponent } from "@shared/components/open-link/open-link.component";
import { IconComponent } from "@shared/components/icon/icon.component";
import { DisplayTimePipe } from "./pipes/display-time.pipe";
import { MonthPickerComponent } from "./components/month-picker/month-picker.component";
import { PhoneComponent } from "./components/phone/phone.component";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { CustomExpansionPanelComponent } from "./components/custom-expansion-panel/custom-expansion-panel.component";

import { PhoneNumberFormatDirective } from "@app/directives/input-formatters/phone-number-format.directive";
import { PostalCodeFormatDirective } from "@app/directives/input-formatters/postal-code-format.directive";
import { HeaderOverlayComponent } from "./components/layout/header-overlay/header-overlay.component";
import { AdminFormFieldComponent } from "./components/admin/admin-form-field/admin-form-field.component";
import { MobileSidenavFrameComponent } from "./components/layout/mobile-sidenav-frame/mobile-sidenav-frame.component";
import { SearchInputComponent } from "./components/form/search-input/search-input.component";
import { DesjardinsMemberEditionComponent } from "./components/project/desjardins-member-edition/desjardins-member-edition.component";
import { CustomInputNumberComponent } from "@shared/components/custom-input-number/custom-input-number.component";
import { DynamicLoadingComponent } from "@shared/components/dynamic-loading/dynamic-loading.component";
import { ProjectUsersConcurencyModule } from "./components/project-users-concurency/project-users-concurency.module";
import { MultiLanguageTabComponent } from "./components/multi-language-tab/multi-language-tab.component";
import { MultiLanguageEditorComponent } from "./components/multi-language-editor/multi-language-editor.component";
import { StandaloneRecaptchaComponent } from "./components/standalone-recaptcha/standalone-recaptcha.component";
import { NgxCaptchaModule } from "ngx-captcha";
import { MarqueeComponent } from "./components/marquee/marquee.component";
import { ImageCropperComponent } from "./components/cropper/image-cropper.component";
import { LoadingComponent } from "@app/shared-v2/components/loading/loading.component";
import { AiDrawerComponent } from "@app/shared-v2/components/ai-drawer/ai-drawer.component";
import { LrdFormFieldComponent } from "@app/shared-v2/components/lrd-form-field/lrd-form-field.component";
import { LrdFormFieldActionComponent } from "@app/shared-v2/components/lrd-form-field/lrd-form-field-action/lrd-form-field-action.component";
import { AiTranslatorComponent } from "@app/shared-v2/components/ai-translator/ai-translator.component";
import { LrdTextCounterComponent } from "@app/shared-v2/components/lrd-text-counter/lrd-text-counter.component";
import { LrdSelectComponent } from "@app/shared-v2/components/lrd-form-field/lrd-select/lrd-select.component";
import { LrdOptionComponent } from "@app/shared-v2/components/lrd-form-field/lrd-select/lrd-option/lrd-option.component";
import { LrdErrorComponent } from "@app/shared-v2/components/lrd-form-field/lrd-error/lrd-error.component";

const materials = [
  MatBadgeModule,
  MatCardModule,
  MatTableModule,
  MatTabsModule,
  MatFormFieldModule,
  MatIconModule,
  MatAutocompleteModule,
  MatDividerModule,
  MatInputModule,
  MatButtonModule,
  MatRadioModule,
  MatSliderModule,
  MatSidenavModule,
  MatMenuModule,
  MatToolbarModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatSelectModule,
  MatProgressBarModule,
  MatTooltipModule,
  MatExpansionModule,
];

@NgModule({
  declarations: [
    MobileDisabledComponent,
    SafePipe,
    FileSizePipe,
    ReplacePipe,
    CroppieLegacyComponent,
    EmptyTableComponent,
    HighlightFirstWord,
    Highlight,
    OptionAutocompleteSearchComponent,
    DatePickerComponent,
    ImageUploaderComponent,
    HandlingErrorMessagesPipe,
    FallbackLanguage,
    DollarSignPipe,
    VideoUrlPipe,
    CurrencyPipeRuchePipe,
    DatePipeRuchePipe,
    PhonePipeRuchePipe,
    BreadcrumbComponent,
    PageContentComponent,
    PageContentWrapperComponent,
    PageContentActionBarComponent,
    FilterBarComponent,
    CheckboxComponent,
    SliderComponent,
    ButtonComponent,
    ProjectSummaryPopupComponent,
    TextAreaComponent,
    LongLocalizePipe,
    SocialShareComponent,
    ConsoleBreadCrumbsComponent,
    NumberInputComponent,
    LowProfileButtonComponent,
    PopupComponent,
    ContributionTypeConverterPipe,
    ProgramRoleConverterPipe,
    ProjectFinAddStatusConverterPipe,
    PaymentStatusConverterPipe,
    YesNoSelectorComponent,
    SelectComponent,
    MobileActionBarComponent,
    ContributionStatusConverterPipe,
    SplitLayoutWithStickyNavigationComponent,
    BannerComponent,
    ErrorsComponent,
    PrintablePageHeaderComponent,
    IconComponent,
    LoupeComponent,
    OpenLinkComponent,
    DisplayTimePipe,
    MonthPickerComponent,
    PhoneComponent,
    CustomExpansionPanelComponent,
    PhoneNumberFormatDirective,
    PostalCodeFormatDirective,
    HeaderOverlayComponent,
    AdminFormFieldComponent,
    MobileSidenavFrameComponent,
    SearchInputComponent,
    DesjardinsMemberEditionComponent,
    CustomInputNumberComponent,
    DynamicLoadingComponent,
    MultiLanguageTabComponent,
    MultiLanguageEditorComponent,
    StandaloneRecaptchaComponent,
    MarqueeComponent,
    ImageCropperComponent,
  ],
  imports: [
    IconTitleContainerComponent,
    CommonModule,
    ...materials,
    ButtonArrayModule,
    AdminSideNavModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    TranslationsModule,
    SnackbarModule,
    InlineEditorModule,
    MatChipsModule,
    RouterModule,
    MatSortModule,
    MatRippleModule,
    NgxCaptchaModule,
    NgxMaskDirective,
    AngularCropperjsModule,
    ButtonV2Component,
    ContextualNavBarComponent,
    LoadingComponent,
    AiDrawerComponent,
    MatSnackBarModule,
    LrdFormFieldComponent,
    LrdFormFieldActionComponent,
    AiTranslatorComponent,
    LrdInputDirective,
    LrdTextCounterComponent,
    LrdSelectComponent,
    LrdOptionComponent,
    LrdErrorComponent,
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    TranslatePipe,
    FileSizePipe,
    ReplacePipe,
    HighlightFirstWord,
    Highlight,
  ],
  exports: [
    ProjectUsersConcurencyModule,
    CommonModule,
    FontAwesomeModule,
    TranslationsModule,
    ColorPickerModule,
    QuillModule,
    CroppieLegacyComponent,
    EmptyTableComponent,
    HighlightFirstWord,
    Highlight,
    SafePipe,
    FileSizePipe,
    ReplacePipe,
    TranslationsModule,
    SnackbarModule,
    FormsModule,
    ReactiveFormsModule,
    OptionAutocompleteSearchComponent,
    InlineEditorModule,
    ...materials,
    DatePickerComponent,
    MobileDisabledComponent,
    ImageUploaderComponent,
    HandlingErrorMessagesPipe,
    FallbackLanguage,
    DollarSignPipe,
    VideoUrlPipe,
    CurrencyPipeRuchePipe,
    DatePipeRuchePipe,
    PhonePipeRuchePipe,
    BreadcrumbComponent,
    PageContentComponent,
    PageContentWrapperComponent,
    PageContentActionBarComponent,
    FilterBarComponent,
    CheckboxComponent,
    SliderComponent,
    ButtonComponent,
    TextAreaComponent,
    LongLocalizePipe,
    ConsoleBreadCrumbsComponent,
    SocialShareComponent,
    NumberInputComponent,
    LowProfileButtonComponent,
    PopupComponent,
    ContributionTypeConverterPipe,
    ProgramRoleConverterPipe,
    ProjectFinAddStatusConverterPipe,
    PaymentStatusConverterPipe,
    SelectComponent,
    YesNoSelectorComponent,
    MobileActionBarComponent,
    ContributionStatusConverterPipe,
    SplitLayoutWithStickyNavigationComponent,
    BannerComponent,
    ErrorsComponent,
    PrintablePageHeaderComponent,
    OpenLinkComponent,
    IconComponent,
    LoupeComponent,
    DisplayTimePipe,
    MonthPickerComponent,
    PhoneComponent,
    CustomExpansionPanelComponent,
    CustomInputNumberComponent,
    PhoneNumberFormatDirective,
    PostalCodeFormatDirective,
    HeaderOverlayComponent,
    AdminFormFieldComponent,
    MobileSidenavFrameComponent,
    SearchInputComponent,
    DesjardinsMemberEditionComponent,
    DynamicLoadingComponent,
    MultiLanguageTabComponent,
    MultiLanguageEditorComponent,
    StandaloneRecaptchaComponent,
    MarqueeComponent,
    LoadingComponent,
    AiDrawerComponent,
    LrdFormFieldComponent,
    LrdFormFieldActionComponent,
    AiTranslatorComponent,
    LrdInputDirective,
    LrdTextCounterComponent,
    LrdSelectComponent,
    LrdOptionComponent,
    LrdErrorComponent,
  ],
})
export class SharedModule {
  private icons: any[] = [
    faFacebookSquare,
    faInstagram,
    faLinkedin,
    faTwitterSquare,
    faYoutubeSquare,
    faGoogle,
    faUser,
    faExchangeAlt,
    faMapMarkerAlt,
    faExchangeAlt,
    faSignOutAlt,
    faChevronLeft,
    faExternalLinkAlt,
    faChevronRight,
    faChevronUp,
    faQuestionCircle,
    faCaretUp,
    faCheck,
    faExclamationCircle,
    faChevronDown,
    faSearch,
    faBars,
    faShoppingCart,
    faNewspaper,
    faEnvelope,
    faCreditCard,
    faFileAlt,
    faExternalLinkAlt,
    faLongArrowAltRight,
  ];

  constructor(private library: FaIconLibrary) {
    library.addIcons(...this.icons);
  }
}
